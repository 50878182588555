'use client'

import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Spinner } from '@design-system/src/components/Spinner'
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'

import {
  IMAGE_PLACEHOLDER_SIZES,
  IMAGE_PLACEHOLDER_SIZES_TWO_COLUMNS_VARIANT,
  IMAGE_SIZES,
  IMAGE_SIZES_TWO_COLUMNS_VARIANT,
  dividerStyled,
} from '.'
import { ArticlePreviewHorizontal, ArticlePreviewHorizontalType } from '../ArticlePreviewHorizontal'

type LoadMoreProps = {
  initialOffset: string | null | undefined
  adIsVisible: boolean
  blockId: string | null | undefined
  hideAuthors?: boolean
  type: 'externalBlocks' | 'additionalBlocks'
  imgPlaceholder?: string
  imgPlaceholderHeight?: number
  imgPlaceholderWidth?: number
}

export const LoadMore = ({
  initialOffset,
  adIsVisible,
  hideAuthors,
  blockId,
  type,
  imgPlaceholder,
  imgPlaceholderHeight,
  imgPlaceholderWidth,
}: PropsWithChildren<LoadMoreProps>) => {
  const ref = useRef<HTMLDivElement>(null)
  const [loadMoreNodes, setLoadMoreNodes] = useState<ArticlePreviewHorizontalType[]>([])
  const currentOffsetRef = useRef<string | undefined | null>(initialOffset)
  const observerRef = useRef<IntersectionObserver | null>(null)
  const [scrollLoad, setScrollLoad] = useState(true)
  const [loading, setLoading] = useState(false)
  const isTwoColumns = adIsVisible
  const sizes = isTwoColumns ? IMAGE_SIZES_TWO_COLUMNS_VARIANT : IMAGE_SIZES
  const placeholderSizes = isTwoColumns ? IMAGE_PLACEHOLDER_SIZES_TWO_COLUMNS_VARIANT : IMAGE_PLACEHOLDER_SIZES

  const loadMore = useCallback(
    async (abortController?: AbortController) => {
      setLoading(true)
      const params = new URLSearchParams({
        nextToken: currentOffsetRef.current || '',
        blockId: blockId || '',
        type: type,
      })
      await fetch(API_ROUTES_NEXT_PUBLIC_SITE.loadMoreArticlesApiUrl + '?' + params.toString())
        .then(res => {
          if (res.ok) {
            return res.json()
          }
          throw new Error('Error fetching data')
        })
        .then(res => {
          const [node, next] = res as [ArticlePreviewHorizontalType[], string]
          if (abortController?.signal.aborted) return

          setLoadMoreNodes(prev => {
            const deduped = prev.filter(el => !node.some(n => n.articleHref === el.articleHref))
            return [...deduped, ...node]
          })
          if (!next) {
            currentOffsetRef.current ??= undefined
            return
          }

          currentOffsetRef.current = next
        })
        .catch(() => {})
        .finally(() => setLoading(false))
    },
    [blockId, type],
  )

  useEffect(() => {
    // make we don't have more than 1 observer
    if (observerRef.current) {
      return
    }
    const signal = new AbortController()

    const element = ref.current

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadMore(signal)
        }
      },
      {
        threshold: 0.5,
      },
    )
    observerRef.current = observer
    if (element && scrollLoad) {
      observer.observe(element)
    }

    return () => {
      signal.abort()
      if (element) {
        observer.unobserve(element)
      }
    }
  }, [loadMore, scrollLoad])

  return (
    <>
      <CustomGrid
        css={{
          mt: '$6',
          bp2: { mt: '$8' },
          bp3: { mt: '$0' },
        }}
        columns={{ base: '1', bp1: '2', bp2: isTwoColumns ? '1' : '2', bp3: '1' }}
        gap={{
          base: 'small',
          bp1: 'medium',
          bp2: isTwoColumns ? 'none' : 'medium',
          bp3: 'none',
        }}>
        {loadMoreNodes?.map(el => (
          <CustomFlex direction="column" key={el.articleHref}>
            <CustomDivider className={dividerStyled({ isTwoColumns })} size="small" orientation="horizontal" />
            <ArticlePreviewHorizontal
              imgPlaceholder={imgPlaceholder}
              imgPlaceholderHeight={imgPlaceholderHeight}
              imgPlaceholderWidth={imgPlaceholderWidth}
              {...el}
              hideAuthors={hideAuthors}
              wrapSectionWithLink={false}
              sizes={sizes}
              placeholderSizes={placeholderSizes}
            />
          </CustomFlex>
        ))}
        <p ref={ref} style={{ opacity: 0 }}>
          LOAD MORE
        </p>
      </CustomGrid>
      <div>{loading && <Spinner />}</div>
    </>
  )
}
