'use client'

import { Box } from '@design-system/src/components/Box'
import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { dateToMDY } from '@design-system/src/utils/date-utils'
import { BlockPubSearchCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import dynamic from 'next/dynamic'
import React, { Suspense } from 'react'

import { TypesenseArticle } from '@models/typesense'
import { fromUnixToUTC } from '@shared/util'

import { BlockViewProps } from '../../../_utils/typescript-utils'
import { ArticlePreviewHorizontal } from '../../ArticlePreviewHorizontal'
import { GoogleAd } from '../../GoogleAd'

const InstantSearchBlock = dynamic(() => import('./InstantSearchBlock').then(module => module.InstantSearchBlock))
const Configure = dynamic(() => import('react-instantsearch-core').then(module => module.Configure))

const Hits = dynamic(() => import('./Hits').then(module => module.Hits))
const TypesenseSearchBox = dynamic(() => import('./TypesenseSearchBox').then(module => module.TypesenseSearchBox))
let timerId: NodeJS.Timeout

const collapsableId = 'block-pub-search'

export const BlockPubSearch: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubSearchCustomPageData }>
> = props => {
  const {
    typesenseHost,
    typesenseSearchKey,
    instantSearchIndexName,
    googleAdConfig,
    imgPlaceholder,
    imgPlaceholderWidth,
    imgPlaceholderHeight,
  } = props.blockCustomData

  return (
    <Gutter css={{ position: 'relative', bgColor: '$gs1' }} data-sho-block-name="BlockPubSearch">
      <Suspense fallback={<></>}>
        <InstantSearchBlock
          typesenseHost={typesenseHost}
          typesenseSearchKey={typesenseSearchKey}
          instantSearchIndexName={instantSearchIndexName}>
          <Configure
            // @ts-ignore
            hitsPerPage={50}
            filters={`releaseDate:<=${Math.floor(Date.now() / 1000)}`}
            attributesToRetrieve={[
              'title',
              'summary',
              'date',
              'articleHref',
              'src',
              'section',
              'image',
              'imageHeight',
              'imageWidth',
              'slug',
              'releaseDate',
              'authors',
            ]}
            facetFilters={['type:Article']}
          />
          <TypesenseSearchBox
            withVariant="full"
            queryHook={(query, search) => {
              clearTimeout(timerId)
              timerId = setTimeout(() => {
                search(query)
              }, 1000)
            }}
          />
          <Text
            css={{
              mt: '$10',
              color: '$gs11',
              mb: '$20',
              textAlign: 'center',
              bp2: {
                textAlign: 'left',
                mb: '$10',
              },
            }}
            oll={false}
            variant="h6">
            LATEST
          </Text>
          <CustomFlex
            direction="column"
            gap={'10'}
            css={{
              bp2: {
                flexDirection: 'row',
              },
            }}>
            <Box css={{ width: '$full' }}>
              <Hits
                hitComponent={({ hit, isLast }: { hit: TypesenseArticle; isLast: boolean }) => {
                  return (
                    <CustomFlex direction="column" justify="between" key={hit.slug}>
                      <ArticlePreviewHorizontal
                        imgPlaceholder={imgPlaceholder}
                        imgPlaceholderWidth={imgPlaceholderWidth}
                        imgPlaceholderHeight={imgPlaceholderHeight}
                        doNotTransformImageURL={false}
                        sectionName={hit.section?.name}
                        sectionHref={hit.section?.href}
                        src={hit.image?.url}
                        date={dateToMDY(new Date(fromUnixToUTC(hit.releaseDate)))}
                        title={hit.title || ''}
                        summary={hit.summary || ''}
                        articleHref={hit.slug}
                        authors={{
                          size: 'small',
                          direction: 'row',
                          authors:
                            hit.authors?.map(author => ({
                              id: author.id,
                              imageSrc: author.imageHref,
                              name: author.name,
                              href: author.href,
                              title: author.position,
                            })) || [],
                        }}
                        imageHeight={hit.image?.height}
                        blurryPlaceholder={hit.image?.placeholder}
                        imageWidth={hit.image?.width}
                        css={{
                          '& img': {
                            objectFit: 'contain',
                            width: '$full',
                          },
                        }}
                        sizes="(max-width: 77.5em) 100vw, (max-width: 87.5em) 75vw, 800px"
                        placeholderSizes="(max-width: 77.5em) 100vw, (max-width: 87.5em) 75vw, 800px"
                      />
                      <CustomDivider
                        css={{
                          my: '$4',
                          display: isLast ? 'none' : 'block',
                          bp2: {
                            my: '$10',
                          },
                        }}
                      />
                    </CustomFlex>
                  )
                }}
              />
            </Box>
            <div id={collapsableId}>
              <GoogleAd
                {...googleAdConfig}
                collapsableId={collapsableId}
                swapAdAtInterval={true}
                css={{
                  display: 'none !important',
                  bp2: {
                    minWidth: '[25% !important]',
                    width: '[25% !important]',
                    display: 'block !important',
                    position: 'sticky',
                    top: '[173px]',
                    height: 'fit',
                  },
                }}
              />
            </div>
          </CustomFlex>
        </InstantSearchBlock>
      </Suspense>
    </Gutter>
  )
}
