'use client'

import { forwardRef, useMemo } from 'react'
import { css } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

export type PdfViewerProps = {
  /**
   * Does not transform the URL if on sho-app.
   * Defaults to false
   */
  doNotTransformURL?: boolean

  /**
   * Explicits if the asset is public or private (AWS).
   * Defaults to false
   */
  isPrivate?: boolean
  /**
   * Set the custom css on the image
   * @default {}
   */
  className?: SystemStyleObject
  /**
   * JWT token used for private assets.
   */
  jwt?: 'string'

  assetKey: string
}

export const PdfViewer = forwardRef<HTMLEmbedElement, PdfViewerProps>(
  ({ className = {}, assetKey, doNotTransformURL = false, isPrivate = false, jwt, ...rest }, forwardedRef) => {
    const transformedSrc = useMemo(() => {
      if (
        !doNotTransformURL &&
        typeof assetKey === 'string' &&
        process.env.NEXT_PUBLIC_PUBLIC_ASSET &&
        process.env.NEXT_PUBLIC_PRIVATE_ASSET &&
        !assetKey.startsWith('/') &&
        !assetKey.startsWith('http')
      ) {
        const baseUrl = isPrivate ? process.env.NEXT_PUBLIC_PRIVATE_ASSET : process.env.NEXT_PUBLIC_PUBLIC_ASSET
        return (assetKey = `https://${baseUrl}/${assetKey}${isPrivate && jwt ? `?jwt=${jwt}` : ''}`)
      }
      return assetKey
    }, [assetKey, doNotTransformURL, isPrivate, jwt])

    if (!transformedSrc) {
      console.error('ShoImage attempted to be used with missing src')
      return null
    }

    return <embed src={`${transformedSrc}#navpanes=0`} type="application/pdf" className={css(className)} />
  },
)

PdfViewer.toString = () => `.pdf-viewer`
PdfViewer.displayName = 'PdfViewer'
