'use client'

import { Box } from '@design-system/src/components/Box'
import { ButtonProps } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import { validEmail } from '@design-system/src/utils/validation-utils'
import parsePhoneNumber from 'libphonenumber-js'
import { signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next-nprogress-bar'
import 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { css } from 'styled-system/css'

import { BlockPubSigninCustomPageData, BlockViewProps, MagicLinkStateType } from '../../_utils/typescript-utils'

interface IFormInput {
  email: string
}

export const BlockPubSignin: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubSigninCustomPageData }>
> = props => {
  const [magicLinkState, setMagicLinkState] = useState<MagicLinkStateType>(props.blockCustomData.initialMagicLinkState)
  const [submitBtnState, setSubmitBtnState] = useState<ButtonProps['buttonState']>('default')
  const router = useRouter()
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({ mode: 'onSubmit' })

  const { customerServiceNumber } = props.blockCustomData
  const { status } = useSession()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('email-sent')) {
      setMagicLinkState('sent')
    }
  }, [])

  useEffect(() => {
    // If user already subscribed, redirect to their account
    if (status === 'authenticated') {
      // Adding timeout so that the redirect UI doesn't flash.
      setTimeout(() => {
        router.push('/')
      }, 5000)
    }
  }, [status, router])

  const onSubmitForm: SubmitHandler<IFormInput> = async formData => {
    setSubmitBtnState('waiting')
    const email = formData.email
    try {
      const url = new URL(location.href)
      url.pathname = '/'
      await signIn('email', {
        email,
        callbackUrl: url.toString(),
        redirect: false,
      })
      setSubmitBtnState('default')
      setMagicLinkState('sent')
    } catch (error) {
      console.error('error signin in:', error)
      setSubmitBtnState('default')
      setMagicLinkState('failed')
    }
  }

  const getGreeting = (): string => {
    if (magicLinkState === 'sent') return 'Check your inbox'
    const now = new Date()
    const hour = now.getHours()
    if (hour < 12) {
      return 'Good Morning,'
    } else if (hour < 18) {
      return 'Good Afternoon,'
    } else {
      return 'Good Evening,'
    }
  }

  const customerServiceNumberFormatted = customerServiceNumber
    ? parsePhoneNumber(customerServiceNumber)?.formatNational()
    : ''
  const customerServiceNumberURI = customerServiceNumber ? parsePhoneNumber(customerServiceNumber)?.getURI() : ''

  return (
    <Gutter
      css={{ bgColor: '$gs3' }}
      variant="bare"
      data-sho-block-name="BlockPubSignin"
      data-sho-block-id={props.blockId}>
      <CustomFlex direction={{ base: 'column', bp3: 'row' }} justify="center" css={signinWrapper}>
        <Gutter>
          <div className={siginContainer}>
            <CustomFlex direction="column" justify="center" align="center" css={{ height: '$full' }}>
              <Text variant="h2" css={{ color: '$gs12' }}>
                {getGreeting()}
              </Text>
              {status === 'authenticated' ? (
                <>
                  <Gutter css={{ paddingLeft: '$0 !important' }}>
                    <Text variant="body1" css={{ color: '$gs12' }}>
                      You are connected, we are redirecting you to the&nbsp;homepage.
                    </Text>
                  </Gutter>
                </>
              ) : magicLinkState === 'sent' ? (
                <Gutter css={{ paddingLeft: '$0 !important' }}>
                  <Text css={{ color: '$gs12' }} variant="subtitle1">
                    We just emailed a magic link to <b>{watch('email')}</b>. Once it arrives, it will be valid for 24
                    hours.
                  </Text>
                </Gutter>
              ) : (
                <>
                  <Box css={{ mb: '$4' }}>
                    <Gutter css={{ paddingLeft: '$0 !important' }}>
                      <Text variant="body1" css={{ color: '$gs11' }}>
                        Sign in or create your account by entering your&nbsp;email.
                      </Text>
                    </Gutter>
                    <form method="post" onSubmit={handleSubmit(onSubmitForm)}>
                      <CustomFlex direction="column" gap={'4'}>
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: 'Email is required',
                            pattern: {
                              value: validEmail,
                              message: 'Please enter a valid email address.',
                            },
                          }}
                          render={({ field }) => (
                            <Textfield
                              {...field}
                              autoFocus={true}
                              fullWidth={true}
                              css={{ textAlign: 'center' }}
                              placeholder="* Enter your email"
                              type="email"
                              name="email"
                              reactFormErrors={errors}
                            />
                          )}
                        />
                        <button className={css({ txtStyle: 'body1' }, customBtn)}>Sign In</button>
                      </CustomFlex>
                    </form>
                    {magicLinkState === 'failed' && (
                      <CustomFlex
                        css={{ backgroundColor: '$ale', mt: '$4', py: '$2', borderRadius: '$3' }}
                        justify="center"
                        align="center">
                        <Text css={{ color: '$aleText', textAlign: 'center' }} variant="caption">
                          The information your entered does not match our records
                        </Text>
                      </CustomFlex>
                    )}
                  </Box>
                </>
              )}
            </CustomFlex>
            {customerServiceNumberFormatted && customerServiceNumberURI && (
              <CustomFlex
                justify="center"
                css={{
                  display: 'none',
                  bp3: {
                    display: 'flex',
                  },
                }}>
                <Text variant="caption" css={{ color: '$gs11' }}>
                  If you need help, please contact Customer Service at
                  <a href={customerServiceNumberURI} target="_blank">
                    <Text variant="caption" css={{ color: '$pri', display: 'inline', textWrap: 'nowrap' }}>
                      &nbsp;{customerServiceNumberFormatted}&nbsp;.
                    </Text>
                  </a>
                </Text>
              </CustomFlex>
            )}
          </div>
        </Gutter>
      </CustomFlex>
    </Gutter>
  )
}

const customBtn = {
  color: '$pri',
  backgroundColor: 'transparent',
  outline: 'none',
  cursor: 'pointer',
  border: 'none',
  _hover: {
    opacity: 0.6,
    transitionProperty: 'opacity',
    transitionDuration: '$normal',
    transitionTimingFunction: 'in-out',
  },
  _focus: {
    outlineWidth: '$1',
    outlineStyle: 'solid',
    outlineColor: '$pri',
  },
} as const

const signinWrapper = {
  bgColor: '$gs1',
  position: 'relative',
  justifyContent: 'center',
  bp3: {
    minHeight: '[80vh]',
  },
} as const

const siginContainer = css({
  color: '$gs2',
  flexGrow: 2,
  maxWidth: '[500px]',
  margin: 'auto',
  height: '$full',
  textAlign: 'center',
  '& form': {
    width: '$full',
  },
})
