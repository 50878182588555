'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { DotElastic } from '@design-system/src/components/DotElastic'
import { Text } from '@design-system/src/components/Text'
import Link from 'next/link'
import { FC } from 'react'
import { cva } from 'styled-system/css'

import { AccountCard } from './AccountCard'

type BlockPubAccountPageUIProps = {
  counponInfo?: string
  // Careful. For Stripe, the mangeSubscriptionUrl sends the user to subscripe page with a query string that allows the 'delete' subscription to show.
  // If we want to do something more sophisticated, we need to change this system.
  manageSubscriptionUrl: string
  onClickProfile: () => void
  userIsSubscribedToPublication?: boolean
  userIsSubscribedToSupportPlan?: boolean
  userEmail?: string
  subscriptionInfo?: string
  customerServiceNumberFormatted?: string
  userHasCanceledAtPeriodEnd?: boolean
  onReactivateSubscriptionClick?: () => void
  showReactivateAccountError?: boolean
  reactivateAccountApiCallInProgress?: boolean
}

export const AccountPageUI: FC<BlockPubAccountPageUIProps> = props => {
  return (
    <CustomGrid
      columns={{ base: '1', bp1: '2' }}
      gap="small"
      css={{
        mt: '$6',
        bp1: {
          mt: '$8',
        },
      }}>
      {/* LEFT CARD */}
      <AccountCard
        title="Your Profile"
        headerCTA={
          <Text variant="body2" css={{ color: '$pri', cursor: 'pointer' }} isLink={true} onClick={props.onClickProfile}>
            Manage
          </Text>
        }>
        <Text variant="overline" css={{ mt: '$2', bp2: { mt: '$6' } }}>
          EMAIL ADDRESS
        </Text>
        {props.userEmail && (
          <Text variant="body1" css={{ mt: '$2', color: '$gs11' }}>
            {props.userEmail}
          </Text>
        )}
      </AccountCard>

      {/* RIGHT CARD */}
      <div>
        {props.showReactivateAccountError && (
          <Text variant="body1" css={{ color: '$pri', mb: '$2' }}>
            There was an error reactivating your account. Please contact customer support
            {props.customerServiceNumberFormatted ? `: ${props.customerServiceNumberFormatted}` : ''}.
          </Text>
        )}
        {props.userIsSubscribedToPublication && (
          <AccountCard
            title="Subscription"
            headerCTA={
              <>
                {props.userIsSubscribedToSupportPlan ? (
                  <Text
                    variant="body2"
                    css={{ color: '$pri', whiteSpace: 'nowrap' }}
                    onClick={() =>
                      alert(
                        `Please contact customer support to make changes to your subscription${props.customerServiceNumberFormatted ? `: ${props.customerServiceNumberFormatted}` : ''}.`,
                      )
                    }>
                    Manage
                  </Text>
                ) : props.userHasCanceledAtPeriodEnd && props.onReactivateSubscriptionClick ? (
                  <CustomFlex
                    direction={'column'}
                    align={{
                      base: 'center',
                      bp1: 'end',
                    }}>
                    <Text
                      variant="body2"
                      isLink={props.reactivateAccountApiCallInProgress ? false : true}
                      css={{
                        color: '$pri',
                        whiteSpace: 'nowrap',
                        _hover: {
                          color: '$pri_L',
                        },
                      }}
                      onClick={
                        props.reactivateAccountApiCallInProgress ? undefined : props.onReactivateSubscriptionClick
                      }>
                      Re-subscribe
                    </Text>
                    {props.reactivateAccountApiCallInProgress && (
                      <DotElastic
                        css={{
                          mt: '$1',
                          bp1: {
                            marginRight: '20px',
                          },
                        }}
                      />
                    )}
                  </CustomFlex>
                ) : (
                  <Link href={props.manageSubscriptionUrl}>
                    <Text variant="body2" css={{ color: '$pri', whiteSpace: 'nowrap' }}>
                      Manage
                    </Text>
                  </Link>
                )}
              </>
            }>
            <Text variant="overline" css={{ mt: '$2', bp2: { mt: '$6' } }}>
              {props.userHasCanceledAtPeriodEnd ? 'SUBSCRIPTION CANCELLED' : 'CURRENT SUBSCRIPTION'}
            </Text>
            {props.subscriptionInfo && (
              <Text variant="body1" className={priceText({ strikeThrough: props.userHasCanceledAtPeriodEnd })}>
                {props.subscriptionInfo}
              </Text>
            )}
            {props.counponInfo && (
              <Text variant="body1" css={{ color: '$pri' }}>
                {props.counponInfo}
              </Text>
            )}
          </AccountCard>
        )}
      </div>
    </CustomGrid>
  )
}

const priceText = cva({
  base: {
    mt: '$2',
    color: '$gs11',
  },
  variants: {
    strikeThrough: {
      true: {
        textDecoration: 'line-through',
      },
      false: {},
    },
  },
  defaultVariants: {
    strikeThrough: false,
  },
})
