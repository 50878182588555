'use client'

import { Box } from '@design-system/src/components/Box'
import { Gutter } from '@design-system/src/components/Gutter'
import { ShoImage } from '@design-system/src/components/ShoImage'
import {
  BlockPubHeaderMenuCustomPageData,
  BlockViewProps,
} from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { usePathname } from 'next/navigation'
import React, { useEffect } from 'react'

import { isAdvertisementVisible } from '../../../_utils/ad-utils'
import { GoogleAd } from '../../GoogleAd'

const collapsableId = 'block-pub-header-menu-leaderboard-ad'

export const LeaderboardAd: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubHeaderMenuCustomPageData
  }> & { isAboveHeader: boolean }
> = props => {
  const {
    blockCustomData: { isAdVisible: userHasChosenLeaderboardToBeVisible, googleAdConfig },
    isAboveHeader,
  } = props
  const [displayBlock, setDisplayBlock] = React.useState(false) // Need to use state as otherwise it will cause an error as we're relying on pathname to determine the UI
  const advertisementIsVisible = isAdvertisementVisible(googleAdConfig)

  const pathname = usePathname()
  const isHomePage = pathname === '/'
  const mayShowOnPage = !((isAboveHeader && !isHomePage) || (!isAboveHeader && isHomePage) || !pathname)
  const showBlock = userHasChosenLeaderboardToBeVisible && advertisementIsVisible && mayShowOnPage

  useEffect(() => {
    // Using this to prevent the following error: https://react.dev/errors/418
    // caused by using pathname to determine the UI
    if (showBlock) {
      setDisplayBlock(true)
    }
  }, [showBlock])

  const imgSrc = googleAdConfig?.customAds?.imgSrc
  const imgHeight = googleAdConfig?.customAds?.imgHeight
  const imgWidth = googleAdConfig?.customAds?.imgWidth

  return displayBlock ? (
    <Box css={{ backgroundColor: '$gs4' }} data-sho-block-name="BlockPubHeaderMenu-LeaderboardAd" id={collapsableId}>
      <Gutter variant={'short'} css={{ overflow: 'auto' }}>
        {googleAdConfig?.adSystem === 'googleAdManager' && (
          <GoogleAd {...googleAdConfig} type="horizontal" collapsableId={collapsableId} />
        )}
        {googleAdConfig?.adSystem === 'custom' && imgSrc && imgHeight && imgWidth && (
          <a href={googleAdConfig.customAds?.href || ''} target="_blank" rel="noreferrer">
            <ShoImage
              src={imgSrc}
              alt="google-ads"
              width={imgWidth}
              height={imgHeight}
              priority={true}
              imageCss={{
                display: 'block',
                objectFit: 'contain',
                margin: '[0 auto]',
                width: 'auto',
                height: 'auto',
                minWidth: '$full',
                maxWidth: '$full',
                maxHeight: '[70vh]',
              }}
              sizes="(min-width: 77.5em) 1120px, (min-width: 37.5em) calc(100vw - 64px), calc(100vw - 48px)"
            />
          </a>
        )}
      </Gutter>
    </Box>
  ) : null
}
