import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Text } from '@design-system/src/components/Text'
import { FC } from 'react'
import { css, cva } from 'styled-system/css'

import { ArticlePreviewHorizontal, ArticlePreviewHorizontalType } from '../ArticlePreviewHorizontal'
import { GoogleAd, GoogleAdProps } from '../GoogleAd'
import { LoadMore } from './ClientComp'

type RelatedArticlesTemplateProps = {
  articles?: ArticlePreviewHorizontalType[]
  googleAdConfig: GoogleAdProps
  adIsVisible: boolean
  blockId: string | undefined | null
  nextToken: string | undefined | null
  type: 'externalBlocks' | 'additionalBlocks'
}

export const IMAGE_SIZES =
  '(min-width: 77.5em) 512px, (min-width: 77.5em) 750px, (min-width: 37.5em) calc(50vw - 64px), calc(100vw - 48px)'
export const IMAGE_SIZES_TWO_COLUMNS_VARIANT =
  '(min-width: 77.5em) 373px, (min-width: 77.5em) 750px, (min-width: 37.5em) calc(50vw - 64px), calc(100vw - 48px)'
export const IMAGE_PLACEHOLDER_SIZES =
  '(min-width: 77.5em) 465px, (min-width: 77.5em) 700px, (min-width: 37.5em) calc(50vw - 96px), calc(100vw - 80px)'
export const IMAGE_PLACEHOLDER_SIZES_TWO_COLUMNS_VARIANT =
  '(min-width: 77.5em) 324px, (min-width: 77.5em) 700px, (min-width: 37.5em) calc(50vw - 96px), calc(100vw - 80px)'

const collapsableId = 'related-articles-ad'

export const RelatedArticlesTemplate: FC<RelatedArticlesTemplateProps> = ({
  articles,
  googleAdConfig,
  blockId,
  nextToken,
  adIsVisible,
  type,
}) => {
  const isTwoColumns = adIsVisible
  const sizes = isTwoColumns ? IMAGE_SIZES_TWO_COLUMNS_VARIANT : IMAGE_SIZES
  const placeholderSizes = isTwoColumns ? IMAGE_PLACEHOLDER_SIZES_TWO_COLUMNS_VARIANT : IMAGE_PLACEHOLDER_SIZES
  const imgPlaceholder = articles?.[0]?.imgPlaceholder
  const imgPlaceholderHeight = articles?.[0]?.imgPlaceholderHeight
  const imgPlaceholderWidth = articles?.[0]?.imgPlaceholderWidth

  return (
    <CustomFlex direction={{ base: 'column', bp2: 'row' }} gap={'10'}>
      <CustomFlex direction={{ base: 'column', bp2: 'row' }} className={articleContainer({ isTwoColumns })}>
        <div className={css({ minWidth: '$full' })}>
          <CustomGrid
            columns={{ base: '1', bp1: '2', bp2: isTwoColumns ? '1' : '2', bp3: '1' }}
            gap={{
              base: 'small',
              bp1: 'medium',
              bp2: isTwoColumns ? 'none' : 'medium',
              bp3: 'none',
            }}>
            {articles?.map((el, index) => (
              <CustomFlex direction="column" key={el.articleHref + index}>
                <ArticlePreviewHorizontal {...el} sizes={sizes} placeholderSizes={placeholderSizes} />
                {index + 1 !== articles.length && (
                  <CustomDivider className={dividerStyled({ isTwoColumns })} size="small" orientation="horizontal" />
                )}
              </CustomFlex>
            ))}
          </CustomGrid>
          <LoadMore
            adIsVisible={adIsVisible}
            blockId={blockId}
            initialOffset={nextToken}
            type={type}
            imgPlaceholder={imgPlaceholder}
            imgPlaceholderHeight={imgPlaceholderHeight}
            imgPlaceholderWidth={imgPlaceholderWidth}
          />
        </div>
      </CustomFlex>
      {adIsVisible && (
        <CustomFlex
          direction="column"
          id={collapsableId}
          css={{
            width: '$full',
            maxWidth: '[400px]',
            margin: '[0 auto]',
            order: 2,
            bp2: {
              margin: '$0',
              width: '[300px]',
              position: 'sticky',
              top: '[146px]',
              height: 'fit',
              order: 0,
            },
          }}>
          <CustomDivider orientation="horizontal" size="medium" css={{ color: '$gs7', mb: '$4' }} />
          <Text variant="overline" css={{ color: '$gs11', mb: '$4', textAlign: 'center' }}>
            Advertisement
          </Text>
          <GoogleAd
            {...googleAdConfig}
            collapsableId={collapsableId}
            swapAdAtInterval={true}
            css={{
              width: '$full',
              bp2: {
                flex: '[1 0 25%]',
                position: 'sticky',
                top: '[146px]',
                height: 'fit',
              },
            }}
          />
        </CustomFlex>
      )}
    </CustomFlex>
  )
}

export const dividerStyled = cva({
  base: {
    display: 'none',
    bp3: { background: '$gs7', mt: '$10', mb: '$10', display: 'block' },
  },
  variants: {
    isTwoColumns: {
      true: {
        bp2: { background: '$gs7', mt: '$10', mb: '$10', display: 'block' },
      },
      false: {
        bp2: { display: 'none' },
      },
    },
    false: {},
  },
  defaultVariants: {
    isTwoColumns: false,
  },
})

const articleContainer = cva({
  base: {
    overflowY: 'hidden',
    width: '$full',
  },
  variants: {
    isTwoColumns: {
      true: {
        bp2: {
          width: '[75%]',
        },
      },
    },
    false: {},
  },
})
