import { ButtonProps } from '@design-system/src/components/Button'
import { ShippingAddress } from '@next-app/src/graphql/operations'
import {
  PROPAY_CREATE_TRANSCTION_IDENTIFIER_ARGS_TYPE,
  PROPAY_CREATE_TRANSCTION_IDENTIFIER_RESPONSE_BODY,
} from 'app/api/protectpay-create-transaction-identifier/route'
import { SUBSCRIBED_USER_PLAN_RECCURENCE_TYPE } from 'app/sites/[site]/[[...page]]/_utils/publication-utils'
import React, { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'
import type { SystemStyleObject } from 'styled-system/types'

import { PropaySubscriptionPlan } from '@models/types'

import { postData } from '../../../../_utils/rest-utils'
import { PlanCard, PlanCardFormInput } from '../SubscibeShared/PlanCard'

type PropayPlanCard = {
  isUserUnauthenticated: boolean
  isTopChoice: boolean
  css?: SystemStyleObject
  toggleOptionFrequency: 'yearly' | 'monthly'
  propaySubscriptionPlan: PropaySubscriptionPlan
  userSubscriptionPlan?: PropaySubscriptionPlan
  userSubscriptionPlanReccurence?: SUBSCRIBED_USER_PLAN_RECCURENCE_TYPE
  handleSubscribeUserToPlan: (email?: string) => Promise<void>
  handleOpenPaymentPortalModal: (transactionId: string) => void
  totalNumberOfPlans: 1 | 2 | 3
}

export const PropayPlanCard: React.FC<PropayPlanCard> = ({
  css: cssProp = {},
  isUserUnauthenticated,
  isTopChoice,
  propaySubscriptionPlan,
  toggleOptionFrequency,
  userSubscriptionPlan,
  userSubscriptionPlanReccurence,
  handleSubscribeUserToPlan,
  handleOpenPaymentPortalModal,
  totalNumberOfPlans,
}) => {
  const [submitBtnState, setSubmitBtnState] = useState<ButtonProps['buttonState']>('default')
  const [errorMsg, setErrorMsg] = useState('') // TODO: place in Parent component
  const planPrice =
    toggleOptionFrequency === 'yearly' ? propaySubscriptionPlan.priceYearly : propaySubscriptionPlan.priceMonthly
  const planIdIsSameAsUserPlan = userSubscriptionPlan?.id === propaySubscriptionPlan.id
  const planRecurrenceIsSameAsUserPlanReccurence =
    (toggleOptionFrequency === 'yearly' && userSubscriptionPlanReccurence === 'yearly') ||
    (toggleOptionFrequency === 'monthly' && userSubscriptionPlanReccurence === 'monthly') ||
    (toggleOptionFrequency === 'monthly' && userSubscriptionPlanReccurence === '28days')
  const userIsSubscribedToPlanAndPrice = planIdIsSameAsUserPlan && planRecurrenceIsSameAsUserPlanReccurence

  const onCreateProtectpayHostedTransactionIdForPortal = async (props: {
    email?: string
    shippingAddress?: NonNullable<Omit<ShippingAddress, '__typename'>>
  }) => {
    const { email, shippingAddress } = props
    setErrorMsg('')
    setSubmitBtnState('waiting')

    try {
      const skinCssUrl = `${window.location.origin}/payment-portal-skin.css`
      console.log('skinCssUrl:', skinCssUrl)
      const res = await postData<
        PROPAY_CREATE_TRANSCTION_IDENTIFIER_ARGS_TYPE,
        PROPAY_CREATE_TRANSCTION_IDENTIFIER_RESPONSE_BODY
      >({
        data: {
          email,
          planId: propaySubscriptionPlan.id,
          price: `${planPrice}`,
          paymentFrequency: toggleOptionFrequency === 'yearly' ? 'YEAR' : propaySubscriptionPlan.monthlyLevy,
          siteUrlOrigin: window.location.origin,
          skinCssUrl,
          requiresShipping: !!propaySubscriptionPlan.requiresShipping,
          shippingAddress,
        },
        url: API_ROUTES_NEXT_PUBLIC_SITE.createProtectpayTransactionIdentifier,
      })
      console.log('res:', res)

      // User transaction has already occured, but for some reason the subscription was not created. We need to create the subscription.
      if (res.createSubscriptionForUser) {
        console.log(
          'createSubscriptionForUser as result of transaction already being successful:',
          res.createSubscriptionForUser,
        )
        handleSubscribeUserToPlan(email)
      } // We got the transaction identifier, open to iframe to complete the transaction
      else if (res.hostedTransactionIdentifier) {
        handleOpenPaymentPortalModal(res.hostedTransactionIdentifier)
      } else if (res.error) {
        setErrorMsg(res.error.message)
      }
    } catch (error) {
      setErrorMsg('An error occured while subscribing. Please try again later.')
    } finally {
      setSubmitBtnState('default')
    }
  }

  const onSubmitUserInfo: SubmitHandler<PlanCardFormInput> = async formData => {
    const email = formData.email
    const shippingAddress =
      formData.address1 && formData.city && formData.state && formData.zip && formData.country
        ? {
            address1: formData.address1,
            address2: formData.address2,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            country: formData.country,
          }
        : undefined

    await onCreateProtectpayHostedTransactionIdForPortal({ email, shippingAddress })
  }

  return (
    <PlanCard
      {...propaySubscriptionPlan}
      css={cssProp}
      isTopChoice={isTopChoice}
      userIsSubscribedToPlanAndPrice={userIsSubscribedToPlanAndPrice}
      onSubmitUserInfo={onSubmitUserInfo}
      onSubscribeToPlan={onCreateProtectpayHostedTransactionIdForPortal}
      errorMsg={errorMsg}
      subscriptionInProgress={submitBtnState === 'waiting'}
      planPrice={planPrice}
      planFrequencyLabel={toggleOptionFrequency === 'yearly' ? 'Year' : 'Month'}
      isUserUnauthenticated={isUserUnauthenticated}
      planRequiresShipping={!!propaySubscriptionPlan.requiresShipping}
      totalNumberOfPlans={totalNumberOfPlans}
    />
  )
}
