import { Box } from '@design-system/src/components/Box'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { Text } from '@design-system/src/components/Text'
import { AuthorsPreviewType } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React from 'react'
import { css, cva } from 'styled-system/css'

import { defaultPriority, defaultSize } from '.'

type AuthorPreviewProps = AuthorsPreviewType['authors']['0'] & {
  overTwoAuthors: boolean
  type: AuthorsPreviewType['size']
  doNotTransformImageURL: AuthorsPreviewType['doNotTransformImageURL']
  titleMustBeVisible?: boolean
  hasLink?: boolean
  priority?: boolean
}
const authorDefaultDirection = 'column'
const defaultHasLink = false

export const AuthorPreview = React.forwardRef<HTMLDivElement, AuthorPreviewProps>(
  (
    {
      id,
      title,
      name,
      overTwoAuthors = false,
      doNotTransformImageURL,
      type = defaultSize,
      imageSrc,
      titleMustBeVisible = false,
      priority = defaultPriority,
      hasLink = defaultHasLink,
    },
    forwardRef,
  ) => (
    <CustomFlex
      ref={forwardRef}
      gap={type === 'small' ? '2' : '4'}
      key={id}
      align="center"
      className={authorContainer({
        direction: type === 'small' ? 'row' : 'column',
        hasMaxWidth: overTwoAuthors && type === 'small',
      })}>
      {!(type === 'small' && overTwoAuthors) && imageSrc && (
        <ShoImage
          className={authorAvatar({
            variant: type,
            hasLink,
          })}
          alt={`${name} avatar`}
          fill={true}
          src={imageSrc}
          doNotTransformURL={doNotTransformImageURL}
          imageCss={css.raw({
            objectFit: 'cover',
          })}
          sizes={type === 'small' ? '(min-width: 77.5em) 35px, 30px' : '(min-width: 77.5em) 90px, 60px'}
          priority={priority}
        />
      )}
      <Box>
        <Text
          isLink={hasLink}
          variant={type === 'small' ? 'body2' : 'h2'}
          css={{
            color: type === 'small' ? '$gs11' : '$gs12',
            textAlign: type === 'small' ? 'left' : 'center',
            bp3: { textAlign: 'left' },
          }}>
          {name}
          {titleMustBeVisible && type === 'small' && title ? ` — ${title}` : ''}
        </Text>
        {type !== 'small' && title && <Text css={{ color: '$gs12' }}>{title}</Text>}
      </Box>
    </CustomFlex>
  ),
)
AuthorPreview.displayName = 'AuthorPreview'

const authorAvatar = cva({
  base: {
    borderRadius: '$round',
    overflow: 'hidden',
    objectFit: 'cover',
  },
  variants: {
    variant: {
      large: {
        width: '[60px]',
        height: '[60px !important]',
        bp2: {
          width: '[90px]',
          minWidth: '[90px]',
          height: '[90px !important]',
        },
      },
      small: {
        width: '[30px]',
        height: '[30px !important]',
        bp2: {
          width: '[35px]',
          minWidth: '[35px]',
          height: '[35px !important]',
        },
      },
    },
    hasLink: {
      true: {
        cursor: 'pointer',
        transitionProperty: 'all',
        transitionDuration: '$slow',
        transitionTimingFunction: 'out',
        '&:hover': {
          transitionProperty: 'all',
          transitionDuration: '$slow',
          transitionTimingFunction: 'in',
          transform: 'scale(1.03)',
        },
      },
      false: {},
    },
  },
  defaultVariants: {
    variant: 'small',
    hasLink: defaultHasLink,
  },
})

const authorContainer = cva({
  base: {
    width: 'fit',
    transitionProperty: 'all',
    transitionDuration: '$slow',
    transitionTimingFunction: 'out',
  },
  variants: {
    direction: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
        bp3: {
          flexDirection: 'row',
        },
      },
    },
    hasMaxWidth: {
      true: {
        maxWidth: '[195px]',
      },
      false: {
        maxWidth: '$full',
      },
    },
  },
  defaultVariants: {
    direction: authorDefaultDirection,
    hasMaxWidth: false,
  },
})
