import { ToggleOptionsVisibilityEnum } from '@next-app/src/graphql/operations'

import {
  AdvertisementSystem,
  BlockPubArticleHeader,
  BlockPubHeaderMenu,
  BlockPubNavSqueeze,
  Paywall,
} from '@models/types'

export const TOP_STORY_MAX_NB_OF_PRI_ARTICLES = 6
export const DEFAULT_PLAN_TOGGLE_FREQUENCY_VISIBILITY: ToggleOptionsVisibilityEnum = 'both'
export const DEFAULT_PLAN_TOGGLE_TO_YEAR = true
export const DEFAULT_AD_SYSTEM: AdvertisementSystem = 'googleAdManager'
export const DEFAULT_ARTICLE_ACCESS: Paywall = 'Public'
export const DEFAULT_EXTERNAL_DISPLAY: BlockPubArticleHeader['externalDisplay'] = 'topStory'
export const DEFAULT_ARTICLE_TYPE: BlockPubArticleHeader['type'] = 'default'
export const DEFAULT_IS_IFRAME: BlockPubArticleHeader['isIframe'] = false
export const DEFAULT_HEADER_MENU_LOGO_SIZE: NonNullable<BlockPubHeaderMenu['logoSize']> = 'small'
export const DEFAULT_NAV_SQUEEZE_LOGO_SIZE: NonNullable<BlockPubNavSqueeze['logoSize']> = 'small'
export const PUB_HEADER_AD_HERO_DEFAULT_VISIBILITY = false
