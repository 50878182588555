'use client'

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@design-system/src/components/Box'
import { ShoImage } from '@design-system/src/components/ShoImage'
import React, { useCallback, useEffect } from 'react'
import { css } from 'styled-system/css'
import { SystemStyleObject } from 'styled-system/types'

import { AdvertisementSystem, CustomAd } from '@models/types'

export type GoogleAdType = 'vertical' | 'horizontal'

export type GoogleAdProps = {
  /**
   * Format of the ad. Defaults to "auto"
   */
  dataAdFormat?: string

  /**
   * If data is null width. Defaults to "true".
   */
  dataFullWidth?: boolean

  /**
   * Type of Google Ad ( Vertical or Horizontal )
   */
  type: GoogleAdType

  /**
   * css customization
   */
  css?: SystemStyleObject

  /**
   * Custom ads which can be used to override the default google ads
   */
  customAds?: CustomAd

  /**
   * Sizes attribute for the image
   */
  sizes?: string

  /**
   * Info for Google Tag Manager or Google Tag Manager 360
   */
  gtmUnitCode?: string

  gtmSizes?: [number, number][]

  gtmMinWidth?: number

  gtmMinHeight?: number

  gtmDivId?: string

  swapAdAtInterval?: boolean

  adSystem: AdvertisementSystem

  // if Google Ad Manager is used and no ads are displayed, the div with this id will be hidden
  collapsableId?: string
}

const REFRESH_VALUE = 'true'
const SECONDS_TO_WAIT_AFTER_VIEWABILITY = 5
let refreshIntervalId: NodeJS.Timeout | null = null

const AD_MOBILE_MAX_WIDTH = 400
const AD_TABLET_MAX_WIDTH = 728
const AD_LAPTOP_MAX_WIDTH = 970
const AD_DESKTOP_MAX_WIDTH = 1200
const AD_LARGE_DESKTOP_MAX_WIDTH = 1920

export const GoogleAd: React.FC<GoogleAdProps> = ({
  dataAdFormat = 'auto',
  dataFullWidth = true,
  type = 'horizontal',
  css: cssProp = {},
  customAds,
  sizes = '(max-width: 77.5em) 100vw, 1200px',
  gtmUnitCode,
  gtmSizes,
  gtmMinWidth,
  gtmMinHeight,
  gtmDivId,
  swapAdAtInterval,
  adSystem,
  collapsableId,
}) => {
  const showGTM = adSystem === 'googleAdManager' && gtmUnitCode && gtmSizes && gtmMinWidth && gtmMinHeight && gtmDivId

  const slotRenderedCallback = useCallback((event: any) => {
    // Slot is not empty, but no visible ad
    const slot = event.slot
    const slotId = slot.getSlotElementId()
    if (gtmDivId === 'div-gpt-ad-vertical-0') {
      console.info(
        'div-gpt-ad-vertical-0',
        event,
        collapsableId,
        slotId,
        gtmUnitCode,
        gtmSizes,
        gtmMinWidth,
        gtmMinHeight,
        gtmDivId,
      )
    }

    if (slotId === gtmDivId) {
      // Since the event listener is triggered whenever an id (this one or anothor) is rendered, we need to check if the event is for this ad
      if (collapsableId && event.isEmpty) {
        const el = window.document.getElementById(collapsableId)
        el?.style.setProperty('display', 'none')
        console.info('The ad slot is empty, hiding the collapsable element.', {
          event,
          collapsableId,
          gtmUnitCode,
          gtmSizes,
          gtmMinWidth,
          gtmMinHeight,
          gtmDivId,
        })
      } else {
        // You might need to define your own logic to check visibility
        const adElement = document.getElementById(slotId)
        const isAdVisible = adElement && adElement.offsetWidth > 0 && adElement.offsetHeight > 0

        if (!isAdVisible) {
          console.info('The ad slot is not empty, but there is no visible ad.', {
            event,
            collapsableId,
            gtmUnitCode,
            gtmSizes,
            gtmMinWidth,
            gtmMinHeight,
            gtmDivId,
          })
          if (collapsableId) {
            const el = window.document.getElementById(collapsableId)
            el?.style.setProperty('display', 'none')
          }
          // Perform additional actions as needed
        } else {
          console.info('An ad is visible in the slot.', {
            event,
            collapsableId,
            slotId,
            adElement,
            gtmUnitCode,
            gtmSizes,
            gtmMinWidth,
            gtmMinHeight,
            gtmDivId,
          })
        }
      }
    }
  }, [])

  useEffect(() => {
    console.info('GoogleAd Load for', gtmDivId)
    const REFRESH_KEY = `REFRESH${gtmUnitCode}`
    let responsiveAdSlot: any
    if (showGTM) {
      window.googletag = window.googletag || { cmd: [] }
      const googletag = window.googletag
      googletag.cmd.push(function () {
        responsiveAdSlot = googletag
          .defineSlot(gtmUnitCode, gtmSizes, gtmDivId)
          .setTargeting(REFRESH_KEY, swapAdAtInterval ? REFRESH_VALUE : 'false')
          .setCollapseEmptyDiv(true)
          // TODO:
          // googletag.pubads().enableLazyLoad(); // Optional: Enable lazy only for ads below the fold
          .addService(googletag.pubads())
        googletag.pubads().addEventListener('slotRenderEnded', slotRenderedCallback)

        const {
          mobileToTabletAdSizes,
          tabletToLaptopAdSizes,
          laptopToDesktopAdSizes,
          mobileAdSizes,
          desktopToLargeDesktopAdSizes,
        } = createAdMappingArray(gtmSizes)

        // https://developers.google.com/publisher-tag/guides/ad-sizes
        if (laptopToDesktopAdSizes.length || tabletToLaptopAdSizes.length || mobileToTabletAdSizes.length) {
          const mapping = googletag
            .sizeMapping()
            .addSize(
              [AD_LARGE_DESKTOP_MAX_WIDTH, 0],
              type === 'horizontal' ? desktopToLargeDesktopAdSizes : mobileAdSizes,
            ) // if vertical type, the UI will not be larger than 300px. So, remove all other sizes
            .addSize([AD_DESKTOP_MAX_WIDTH, 0], type === 'horizontal' ? laptopToDesktopAdSizes : mobileAdSizes) // if vertical type, the UI will not be larger than 300px. So, remove all other sizes
            .addSize([AD_LAPTOP_MAX_WIDTH, 0], type === 'horizontal' ? tabletToLaptopAdSizes : mobileAdSizes)
            .addSize([AD_TABLET_MAX_WIDTH, 0], type === 'horizontal' ? mobileToTabletAdSizes : mobileAdSizes)
            .addSize([0, 0], mobileAdSizes)
            .build()
          responsiveAdSlot.defineSizeMapping(mapping)
        }

        if (swapAdAtInterval) {
          // DOC: https://developers.google.com/publisher-tag/guides/control-ad-loading#refresh
          googletag.pubads().addEventListener('impressionViewable', function (event) {
            const slot = event.slot
            if (slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) > -1) {
              refreshIntervalId = setTimeout(function () {
                googletag.pubads().refresh([slot])
              }, SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000)
            }
          })
        }
        googletag.enableServices()
        googletag.cmd.push(function () {
          console.info('Displaying ad slot', gtmDivId, responsiveAdSlot.getSlotId().getId())
          googletag.display(gtmDivId)
        })
      })
    }

    return () => {
      // destroy all ad slots
      const { googletag } = window
      if (googletag && googletag.cmd) {
        googletag.cmd.push(function () {
          console.info('Destroying ad slot', responsiveAdSlot.getSlotId().getId())
          googletag.destroySlots([responsiveAdSlot])
        })
      }
      if (window.googletag && window.googletag.pubads) {
        window.googletag.pubads().removeEventListener('slotRenderEnded', slotRenderedCallback)
      }
      if (refreshIntervalId) {
        clearTimeout(refreshIntervalId)
      }
    }
  }, [])

  if (adSystem === 'custom' && customAds) {
    const imgHeight = customAds.imgHeight
    const imgWidth = customAds.imgWidth
    const imgSrc = customAds.imgSrc
    const imgHref = customAds.href

    if (imgSrc && imgHeight && imgWidth) {
      return (
        <Box className={css(googleAdContainer, cssProp)}>
          <Box css={{ textAlign: 'center' }}>
            {imgHref ? (
              <a href={imgHref} target="_blank">
                <ShoImage
                  src={imgSrc}
                  alt="custom ad"
                  width={imgWidth}
                  height={imgHeight}
                  imageCss={imgCss}
                  sizes={sizes}
                />
              </a>
            ) : (
              <ShoImage
                src={imgSrc}
                alt="custom ad"
                width={imgWidth}
                height={imgHeight}
                imageCss={imgCss}
                sizes={sizes}
              />
            )}
          </Box>
        </Box>
      )
    }
  }

  if (showGTM) {
    return (
      <div
        id={gtmDivId}
        data-ad-unit={gtmUnitCode}
        className={css(
          {
            display: 'flex',
            justifyContent: 'center',
          },
          cssProp,
        )}
        style={{
          minWidth: `${gtmMinWidth}px`,
          minHeight: `${gtmMinHeight}px`,
        }}
      />
    )
  }

  return null
}

type SizeArr = [number, number][]
const createAdMappingArray = (
  gtmSizes: [number, number][],
): {
  mobileAdSizes: SizeArr
  mobileToTabletAdSizes: SizeArr
  tabletToLaptopAdSizes: SizeArr
  laptopToDesktopAdSizes: SizeArr
  desktopToLargeDesktopAdSizes: SizeArr
} => {
  let mobileAdSizes: SizeArr = []
  let mobileToTabletAdSizes: SizeArr = []
  let tabletToLaptopAdSizes: SizeArr = []
  let laptopToDesktopAdSizes: SizeArr = []
  let desktopToLargeDesktopAdSizes: SizeArr = []

  gtmSizes.forEach(size => {
    const adWidth = size[0]
    if (adWidth > AD_LARGE_DESKTOP_MAX_WIDTH) {
      // exclude add
    } else if (adWidth > AD_DESKTOP_MAX_WIDTH) {
      desktopToLargeDesktopAdSizes.push(size)
    } else if (adWidth > AD_LAPTOP_MAX_WIDTH) {
      laptopToDesktopAdSizes.push(size)
    } else if (adWidth > AD_TABLET_MAX_WIDTH) {
      tabletToLaptopAdSizes.push(size)
    } else if (adWidth > AD_MOBILE_MAX_WIDTH) {
      mobileToTabletAdSizes.push(size)
    } else mobileAdSizes.push(size)
  })

  if (!mobileToTabletAdSizes.length) mobileToTabletAdSizes = mobileAdSizes
  // Now, showing mobile/tablet ads on laptop if there are no ads for mobile/tablet
  if (!tabletToLaptopAdSizes.length) tabletToLaptopAdSizes = mobileToTabletAdSizes
  // Now, showing laptop ads on desktop if there are no ads for laptop
  if (!laptopToDesktopAdSizes.length) laptopToDesktopAdSizes = tabletToLaptopAdSizes
  // Now, showing desktop ads on large desktop if there are no ads for desktop
  if (!desktopToLargeDesktopAdSizes.length) desktopToLargeDesktopAdSizes = laptopToDesktopAdSizes

  return {
    mobileAdSizes,
    mobileToTabletAdSizes,
    tabletToLaptopAdSizes,
    laptopToDesktopAdSizes,
    desktopToLargeDesktopAdSizes,
  }
}

const imgCss = css.raw({
  mx: '$auto',
  maxHeight: '[60vh]',
  width: '$auto',
  height: '$auto',
  maxWidth: '$full',
  display: 'block',
})

const googleAdContainer = css.raw({
  bgColor: '$gs3',
  width: '$full',
})
