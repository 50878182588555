'use client'

import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Switch } from '@design-system/src/components/Switch'
import { Text } from '@design-system/src/components/Text'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import { loadStripe } from '@stripe/stripe-js/pure'
import { useSession } from 'next-auth/react'
import { BlockPubSubscribeCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { useSearchParams } from 'next/navigation'
import React, { useMemo, useState } from 'react'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'

import { SubscriptionPlan } from '@models/types'

import {
  getSubscriptionIdForPublication,
  getSubscriptionRecurrence,
  getUserSubscriptionPlan,
  isUserSubscribedToNewsletter,
  isUserSubscribedToPublication,
} from '../../../../_utils/publication-utils'
import { BlockViewProps } from '../../../../_utils/typescript-utils'
import { BlockModal } from '../../../BlockModal'
import { CancelSubscriptionModal } from '../SubscibeShared/CancelSubscriptionModal'
import { CancelationFormInput, CancelationReasonModal } from '../SubscibeShared/CancelationReasonModal'
import { ConfirmSubscriptionCancelModal } from '../SubscibeShared/ConfirmSubscriptionCancelModal'
import { StripePlanCard } from '../SubscribeStripe/StripePlanCard'

export const SubscribeStripe: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubSubscribeCustomPageData
  }>
> = props => {
  const searchParams = useSearchParams()
  const {
    discountPromotionalText,
    description,
    toggleDefaultsToYear,
    applyDiscountApiUrl,
    subscribeToNewsletterApiUrl,
    discountCode,
    discountApplicationSuccessText,
    newsletterId,
    createSubscriberWithStripeAccountApiUrl,
    updateSubscriberWithStripeAccountApiUrl,
    stripePublishableKey,
    stripePlans,
    stripeSupportPlans,
    propayPlans,
    connectedSubscriptionSystem,
    disclaimer,
  } = props.blockCustomData

  // Special case. If only one plan and both options are visible, show the same plan but with both options side by side
  const specialCaseOnePlanAndBothOptionsVisible =
    stripePlans.length === 1 &&
    stripePlans[0].stripePriceIdMonthly &&
    stripePlans[0].stripePriceIdYearly &&
    props.blockCustomData.toggleOptionsVisibility === 'both'

  let toggleOptionsVisibility: StripePlanCard['toggleOptionsVisibility'] = specialCaseOnePlanAndBothOptionsVisible
    ? 'none'
    : props.blockCustomData.toggleOptionsVisibility

  const { data: session, status } = useSession()
  // if we have 3 plans, put first one in the middle, if we have 2 plans, put first one last
  const orderedPlans = useMemo<SubscriptionPlan[]>(() => {
    if (specialCaseOnePlanAndBothOptionsVisible) {
      toggleOptionsVisibility = 'none'
      return [stripePlans[0], stripePlans[0]]
    }

    return stripePlans.length === 3
      ? [stripePlans[1], stripePlans[0], stripePlans[2]]
      : stripePlans.length === 2
        ? [stripePlans[1], stripePlans[0]]
        : stripePlans
  }, [stripePlans, specialCaseOnePlanAndBothOptionsVisible])

  const subscribedPlanRecurrence = getSubscriptionRecurrence({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })

  // if toggleOption is true, the user is selecting the yearly plan, else the user is selecting the monthly plan
  const [toggleOption, setToggleOption] = useState(() => {
    let toggleDefault = toggleDefaultsToYear
    // If user is subscribed, it overwrides the default option set in the CMS so that the toggle
    // option is that of the user's subscribed plan
    if (subscribedPlanRecurrence) {
      toggleDefault = subscribedPlanRecurrence === 'yearly' ? true : false
    }
    return toggleDefault
  })

  const [isCancelReasonModalOpen, setIsOpenCancelReasonModal] = useState(false)
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] = useState(false)
  const [subscriptionCancelledModalOpen, setSubscriptionCancelledModalOpen] = useState(false)

  const [thankyouModalOpen, setThankyouModalOpen] = useState(false)
  const [feedbackFormReason, setFeedbackFormReason] = useState<CancelationFormInput>()

  const isUserSubscribed = isUserSubscribedToPublication({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })
  const subscriptionId = getSubscriptionIdForPublication({
    status,
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  })
  const subscribedUserPlan = getUserSubscriptionPlan({
    session,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
    propayPlans,
  })
  const isUserSubscribedNewsLetter = isUserSubscribedToNewsletter(status, session, newsletterId)

  return (
    <>
      <CustomFlex
        css={{ maxWidth: '[750px]', margin: 'auto', mb: '$5', px: '$8' }}
        direction="column"
        justify="center"
        align="center">
        <WysiwygReader
          initialValue={description}
          css={{
            color: '$gs12',
            '&p[data-wisiwig-body1="true"]': {
              color: '$gs11',
            },
            textAlign: 'center',
          }}
        />
        {toggleOptionsVisibility === 'both' && (
          <Switch
            floatLabels={true}
            name="Recurrency Selector"
            css={{ mt: '$10', justifyContent: 'center', width: 'fit-content' }}
            leftText="Monthly"
            rightText="Yearly"
            checked={toggleOption}
            onChange={(checked: boolean) => setToggleOption(checked)}
          />
        )}
      </CustomFlex>
      {toggleOptionsVisibility === 'none' && (
        <CustomFlex
          gap="3"
          direction={'column'}
          css={{
            mb: '$10',
            width: 'fit-content',
            textAlign: 'center',
            marginLeft: '$auto',
            marginRight: '$auto',
          }}>
          {orderedPlans[0].name && <Text variant="h3">Choose your plan for {orderedPlans[0].name}</Text>}
          {orderedPlans[0].subtitle && <Text variant="body1">{orderedPlans[0].subtitle}</Text>}
        </CustomFlex>
      )}

      <CustomGrid
        css={{
          justifyItems: 'center',
          bp3: {
            justifyItems: orderedPlans?.length === 3 ? 'unset' : 'center',
          },
        }}
        columns={{
          base: '1',
          bp3: orderedPlans?.length === 1 ? '1' : orderedPlans?.length === 2 ? '2' : '3',
        }}
        gap="small">
        {orderedPlans?.map((plan, index) => {
          const userIsSubscribedToPlan =
            subscribedUserPlan?.system === 'STRIPE' && subscribedUserPlan.subscription?.productId === plan.productId
          const toggleIsSameAsSubscribedPlanRecurrence =
            (subscribedPlanRecurrence === 'yearly' && toggleOption) ||
            (subscribedPlanRecurrence === 'weekly' && !toggleOption)

          const userIsSubscribedToPlanAndPrice = userIsSubscribedToPlan && toggleIsSameAsSubscribedPlanRecurrence
          let planType: StripePlanCard['planType'] = toggleOption ? 'yearly' : 'monthly'

          // Special case. If only one plan and both options are visible, show the same plan but with both options side by side.
          // In this case, we've added the same plan twice to the orderedPlans array.
          // The first plan is the monthly plan and the second plan is the yearly plan.
          if (toggleOptionsVisibility === 'none') {
            planType = index === 0 ? 'monthly' : 'yearly'
          }

          return (
            <StripePlanCard
              isTopChoice={index === 1}
              key={plan.name}
              toggleOptionsVisibility={toggleOptionsVisibility}
              {...plan}
              name={toggleOptionsVisibility === 'none' ? undefined : plan.name}
              subtitle={toggleOptionsVisibility === 'none' ? undefined : plan.subtitle}
              planSummary={toggleOptionsVisibility === 'none' ? undefined : plan.planSummary}
              benefits={toggleOptionsVisibility === 'none' ? undefined : plan.benefits}
              status={status}
              loadStripe={loadStripe}
              stripePublishableKey={stripePublishableKey}
              userHasASubscription={isUserSubscribed}
              userIsSubscribedToPlanAndPrice={userIsSubscribedToPlanAndPrice}
              createSubscriberWithStripeAccountApiUrl={createSubscriberWithStripeAccountApiUrl}
              updateSubscriberWithStripeAccountApiUrl={updateSubscriberWithStripeAccountApiUrl}
              planType={planType}
              totalNumberOfPlans={orderedPlans.length as 1 | 2 | 3}
              css={{
                order: index === 1 ? '[-1]' : index,
                bp1: {
                  order: index === 1 ? '[-1]' : index,
                  flex: index === 1 ? '[1 0 100%]' : '[none]',
                },
                bp3: {
                  order: index === 1 ? 1 : index,
                  flex: 'none',
                },
              }}
            />
          )
        })}
      </CustomGrid>

      {toggleOptionsVisibility === 'none' && (
        <CustomFlex
          gap="3"
          direction={'column'}
          css={{
            mt: '$10',
            width: 'fit-content',
            textAlign: 'center',
            marginLeft: '$auto',
            marginRight: '$auto',
          }}>
          {orderedPlans[0].planSummary && (
            <>
              <Text variant="body2">{orderedPlans[0].planSummary}</Text>
              <CustomDivider orientation="horizontal" size="small" css={{ color: '$gs6' }} />
            </>
          )}
          {orderedPlans[0].benefits && <WysiwygReader initialValue={orderedPlans[0].benefits} />}
        </CustomFlex>
      )}

      {isUserSubscribed && status === 'authenticated' && searchParams.get('action') === 'manage-subscription' && (
        <Text
          variant="caption"
          css={{ color: '$pri', margin: 'auto', width: 'fit', mt: '$10', cursor: 'pointer' }}
          onClick={() => setIsOpenCancelReasonModal(true)}>
          Want to cancel your subscription? Click Here.
        </Text>
      )}
      {!!disclaimer?.length && (
        <WysiwygReader
          initialValue={disclaimer}
          css={{
            mt: '$20',
            color: '$gs11',
            '&p[data-wisiwig-body1="true"]': {
              color: '$gs11',
            },
            textAlign: 'center',
          }}
        />
      )}
      <CancelationReasonModal
        open={isCancelReasonModalOpen}
        setOpen={setIsOpenCancelReasonModal}
        setIsCancelSubscriptionModalOpen={setIsCancelSubscriptionModalOpen}
        setFeedbackFormReason={setFeedbackFormReason}
      />
      <CancelSubscriptionModal
        open={isCancelSubscriptionModalOpen}
        setOpen={setIsCancelSubscriptionModalOpen}
        setSubscriptionCancelledModalOpen={setSubscriptionCancelledModalOpen}
        subscriptionId={subscriptionId}
        cancelSubscriptionApiUrl={API_ROUTES_NEXT_PUBLIC_SITE.cancelStripeSubscriptionApiUrl}
        // discountPromotionalText={discountPromotionalText}
        // applyDiscountApiUrl={applyDiscountApiUrl}
        // feedbackFormReason={feedbackFormReason}
        // discountCode={discountCode}
        // discountApplicationSuccessText={discountApplicationSuccessText}
      />
      {isUserSubscribed && status === 'authenticated' && (
        <ConfirmSubscriptionCancelModal
          open={subscriptionCancelledModalOpen}
          setOpen={setSubscriptionCancelledModalOpen}
          subscribeToNewsletterApiUrl={subscribeToNewsletterApiUrl}
          setThankyouModalOpen={setThankyouModalOpen}
          newsletterId={newsletterId}
          subscribed={isUserSubscribedNewsLetter}
          userEmail={session?.user?.email}
        />
      )}

      <BlockModal
        openModal={thankyouModalOpen}
        onCloseModalCallback={setThankyouModalOpen}
        title="Thank You For Signing Up!">
        <Text variant="subtitle1" css={{ color: '$gs12', textAlign: 'center' }}>
          You have successfully signed up for the Newsletter.
        </Text>
      </BlockModal>
    </>
  )
}
