'use client'

import * as SwitchPrimitive from '@radix-ui/react-switch'
import React from 'react'
import { css, cva } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

import { TypographyVariants } from '../utils/typescript-utils'
import { CustomFlex } from './CustomFlex'
import { Icon } from './Icon'
import { Text } from './Text'

export interface SwitchsProps {
  /**
   *  Check aschild.
   */
  aschild?: boolean

  /**
   *  Give css classname to switch.
   */
  className?: string

  /**
   *  Set css property to switch.
   */
  css?: SystemStyleObject

  /**
   *  Left switch text.
   */
  leftText?: string

  /**
   *  Right switch text.
   */
  rightText?: string

  /**
   * Text variant for the left and right text.
   * @default caption
   */
  textVariant?: TypographyVariants

  /**
   *  Set material ui icon for switch.
   */
  iconLeft?: React.ReactNode

  /**
   *  Set material ui icon for switch.
   */
  iconRight?: React.ReactNode

  /**
   *  Set disabled property.
   */
  disabled?: boolean

  /**
   *  Name of switch.
   */
  name?: string

  /**
   *  Call back function when switch changes.
   */
  onChange?: (value: boolean) => void

  /**
   *  Required property.
   */
  required?: boolean

  /**
   *  Set default checked.
   */
  defaultChecked?: boolean

  /**
   *  Value of switch.
   */
  checked?: boolean | null

  /**
   *  Make labels float outside of document flow.
   * This is useful for centering the switch in a flex container.
   */
  floatLabels?: boolean
}

export const Switch: React.FC<SwitchsProps> = ({
  className,
  css: cssProp = {},
  leftText,
  rightText = '',
  iconLeft,
  iconRight,
  disabled = false,
  onChange = () => {},
  defaultChecked = false,
  checked = false,
  textVariant = 'caption',
  name,
  floatLabels,
}) => {
  return (
    <CustomFlex className={className} css={{ position: 'relative', ...cssProp }} gap={'3'} align="center">
      {(iconLeft || leftText) && (
        <div
          className={iconContainer({
            floatLabels,
            floatPosition: 'left',
          })}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            onChange(false)
          }}>
          {iconLeft && (
            <Icon
              reactIcon={iconLeft}
              className={iconAndTextColor({
                active: checked || defaultChecked,
              })}
              pointer={true}
            />
          )}
          {leftText && (
            <Text
              variant={textVariant}
              className={iconAndTextColor({
                active: checked || defaultChecked,
              })}>
              {leftText}
            </Text>
          )}
        </div>
      )}
      <SwitchPrimitive.Root
        className={switchRoot}
        name={name}
        aria-label={name}
        defaultChecked={defaultChecked}
        checked={checked || defaultChecked}
        onCheckedChange={checked => {
          onChange(checked)
        }}
        disabled={disabled}>
        <SwitchPrimitive.Thumb className={styledThumb} />
      </SwitchPrimitive.Root>
      {(iconRight || rightText) && (
        <div
          className={iconContainer({
            floatLabels,
            floatPosition: 'right',
          })}
          onClick={() => onChange(true)}>
          {iconRight && (
            <Icon
              reactIcon={iconRight}
              className={iconAndTextColor({
                active: checked || defaultChecked,
              })}
              pointer={true}
            />
          )}
          {rightText && (
            <Text
              variant={textVariant}
              className={iconAndTextColor({
                active: checked || defaultChecked,
              })}>
              {rightText}
            </Text>
          )}
        </div>
      )}
    </CustomFlex>
  )
}

const iconAndTextColor = cva({
  variants: {
    active: {
      true: {
        color: '$gs12',
      },
      false: {
        color: '$gs11',
      },
    },
  },
})

const iconContainer = cva({
  base: {
    width: 'fit',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '$1',
    flexDirection: 'row',
  },
  variants: {
    floatLabels: {
      true: {
        position: 'absolute',
      },
      false: {},
    },
    floatPosition: {
      right: {},
      left: {},
    },
  },
  compoundVariants: [
    {
      floatLabels: true,
      floatPosition: 'left',
      css: {
        left: '[calc({sizes.$2} * -1)]',
        transform: 'translateX(-100%)',
      },
    },
    {
      floatLabels: true,
      floatPosition: 'right',
      css: {
        right: '[calc({sizes.$2} * -1)]',
        transform: 'translateX(100%)',
      },
    },
  ],
})

const switchRoot = css({
  all: 'unset',
  width: '$13',
  height: '$6',
  borderRadius: '[14px]',
  position: 'relative',
  boxSizing: 'border-box',
  cursor: 'pointer',
  pl: '$1',
  '&[data-state="checked"]': {
    backgroundColor: '$pri',
  },
  '&[data-state="unchecked"]': {
    backgroundColor: '$gs11',
  },
  '&[data-disabled]': {
    backgroundColor: '$gs8',
    cursor: 'not-allowed',
    _hover: {
      backgroundColor: '$gs8',
      borderStyle: 'none',
    },
  },
})

const styledThumb = css({
  display: 'block',
  width: '$4',
  height: '$4',
  backgroundColor: '$gs1',
  borderRadius: '$round',
  transitionProperty: 'transform',
  transitionDuration: '$fast',
  transform: 'translateX(2px)',
  '&[data-state="checked"]': {
    transform: 'translateX(26px)',
  },
})
