'use client'

import React from 'react'
import { css, cva } from 'styled-system/css'

import { ShoImage } from '../ShoImage'
import { Text } from '../Text'

type ImageAssetProps = {
  href?: string
  description?: string
  credits?: string
  width?: number
  height?: number
  children?: React.ReactNode
  attributes: Record<string, unknown>
  blurryPlaceholder?: string
  onClick?: () => void
}

export const ImageAsset: React.FC<ImageAssetProps> = props => {
  const { href, description, children, credits, attributes, width, height, blurryPlaceholder, onClick } = props

  return href && width && height ? (
    <div {...attributes} onClick={onClick}>
      <div className={imageContainer}>
        <ShoImage
          src={href}
          alt={description || 'Wysiwyg image'}
          width={width}
          height={height}
          blurryPlaceholder={blurryPlaceholder}
          imageCss={css.raw({
            width: 'auto',
            height: 'auto',
            maxWidth: '$full',
            maxHeight: '[50vh]',
            objectFit: 'contain',
            display: 'block',
            mx: '[auto]',
          })}
        />
      </div>
      <ImageCredits description={description} credits={credits} />
      {children}
    </div>
  ) : null
}

type LocalImageProps = {
  href?: string
  children: React.ReactNode
  attributes: Record<string, unknown>
}

export const LocalImage: React.FC<LocalImageProps> = props => {
  const { href, children, attributes } = props

  return (
    <div
      {...attributes}
      className={container({
        boxShadow: false,
      })}>
      {' '}
      <div className={imageContainer}>
        <img src={href} alt="local-image" className={image} />
      </div>
      {children}
    </div>
  )
}

type ExternalImageProps = {
  href?: string
  description?: string
  credits?: string
  children: React.ReactNode
  attributes: Record<string, unknown>
}

export const ExternalImage: React.FC<ExternalImageProps> = props => {
  const { href, description, children, credits, attributes } = props

  return (
    <div {...attributes}>
      <div className={imageContainer}>
        <img src={href} alt={description} className={image} />
      </div>
      <ImageCredits description={description} credits={credits} />
      {children}
    </div>
  )
}

// css={{ color: '$gs12', p: '$4', display: 'inline-block', margin: '0 auto', minWidth: 'calc(100% - $8)' }}> */ // TODO: check above works
const ImageCredits: React.FC<{ description?: string; credits?: string }> = ({ description, credits }) =>
  credits || description ? (
    <Text
      variant="caption"
      css={{ color: '$gs12', p: '$4', display: 'inline-block', mx: '[auto]', minWidth: '[calc(100% - {sizes.$8})]' }}>
      {description}
      {credits && <span className={credit}>{credits}</span>}
    </Text>
  ) : null

const credit = css({
  color: '$gs11',
  pl: '$3',
})

const image = css({
  maxHeight: '[50vh]',
  maxWidth: '$full',
  margin: '[0px auto]',
  objectFit: 'contain',
  bp1: {
    maxWidth: '[750px]',
    height: 'auto',
  },
})

const container = cva({
  base: {
    mt: '$4',
    mb: '$4',
    ml: '[auto]',
    mr: '[auto]',
    maxWidth: '[750px]',
  },
  variants: {
    boxShadow: {
      true: {
        boxShadow: '[0 0 3px 3px {colors.$gs6}]',
      },
      false: {
        boxShadow: '[none]',
      },
    },
  },
  defaultVariants: {
    boxShadow: false,
  },
})

const imageContainer = css({
  backgroundColor: '$gs7',
})
